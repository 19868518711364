import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
	const [toggleState, setToggleState] = useState(1);

	return (
		<section className="qualification section">
			<h2 className="section__title">Qualification</h2>
			<span className="section__subtitle">My Personal Journey</span>

			<div className="qualification__container container">
				<div className="qualification__tabs">
					<div
						className={
							toggleState === 1
								? "qualification__button qualification__active button--flex"
								: "qualification__button button--flex"
						}
						onClick={() => setToggleState(1)}
					>
						<i className="uil uil-graduation-cap qualification__icon"></i>
						Education
					</div>

					<div
						className={
							toggleState === 2
								? "qualification__button qualification__active button--flex"
								: "qualification__button button--flex"
						}
						onClick={() => setToggleState(2)}
					>
						<i className="uil uil-briefcase-alt qualification__icon"></i>
						Experience
					</div>
				</div>

				<div className="qualification__sections">
					<div
						className={
							toggleState === 1
								? "qualification__content qualification__content-active"
								: "qualification__content"
						}
					>
						<div className="qualification__data">
							<div>
								<h3 className="qualification__title">Web Developer</h3>
								<span className="qualification__subtitle">Udemy - Spain</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i>2022-present
								</div>
							</div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>
						</div>

						<div className="qualification__data">
							<div></div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>

							<div>
								<h3 className="qualification__title">UI/UX Designer</h3>
								<span className="qualification__subtitle">Udemy - Spain</span>
								<div className="qualification__calender">
									<i className="uil uil-calendar-alt"></i>2021 - 2022
								</div>
							</div>
						</div>

						<div className="qualification__data">
							<div>
								<h3 className="qualification__title">Web Design</h3>
								<span className="qualification__subtitle">Udemy - Spain</span>
								<div className="qualification__calender">
									<i className="uil uil-calendar-alt"></i>2020 - 2021
								</div>
							</div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>
						</div>

						<div className="qualification__data">
							<div></div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>

							<div>
								<h3 className="qualification__title">Content creation</h3>
								<span className="qualification__subtitle">Udemy - Spain</span>
								<div className="qualification__calender">
									<i className="uil uil-calendar-alt"></i>2019
								</div>
							</div>
						</div>
					</div>

					<div
						className={
							toggleState === 2
								? "qualification__content qualification__content-active"
								: "qualification__content"
						}
					>
						<div className="qualification__data">
							<div>
								<h3 className="qualification__title">Product Designer</h3>
								<span className="qualification__subtitle">
									Apple Inc. - Spain
								</span>
								<div className="qualification__calender">
									<i className="uil uil-calendar-alt"></i>2022-present
								</div>
							</div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>
						</div>

						<div className="qualification__data">
							<div></div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>

							<div>
								<h3 className="qualification__title">UI/UX Designer</h3>
								<span className="qualification__subtitle">
									Microsoft - Spain
								</span>
								<div className="qualification__calender">
									<i className="uil uil-calendar-alt"></i>2021 - 2022
								</div>
							</div>
						</div>

						<div className="qualification__data">
							<div>
								<h3 className="qualification__title">Web Designer</h3>
								<span className="qualification__subtitle">Figma - Spain</span>
								<div className="qualification__calender">
									<i className="uil uil-calendar-alt"></i>2020 - 2021
								</div>
							</div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Qualification;
