import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";

export const projectsData = [
	{
		id: 1,
		image: Work1,
		title: "Web design",
		category: "Web",
	},
	{
		id: 2,
		image: Work2,
		title: "App movil",
		category: "App",
	},
	{
		id: 3,
		image: Work3,
		title: "Brand design",
		category: "Design",
	},
	{
		id: 4,
		image: Work4,
		title: "App movil",
		category: "App",
	},
	{
		id: 5,
		image: Work5,
		title: "Web design",
		category: "Web",
	},
];

export const projectsNav = [
	{
		name: "All",
	},
	{
		name: "Web",
	},
	{
		name: "App",
	},
	{
		name: "Design",
	},
];
